import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { LanguageService } from '@services/language/language.service';
import * as actions from './policy.actions';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})

export class PolicyEffects {

  constructor(
    private readonly languageService: LanguageService,
    private readonly actions$: Actions,
  ) { }

  @Effect()
  // tslint:disable-next-line: no-any
  geti18nPlanName: Observable<any> = this.actions$.pipe(
    ofType<actions.UpdatePolicy>(actions.UPDATE_POLICY),
    map(data => data.changes.name),
    mergeMap(name => this.languageService.get(`TARIFICATION.${name}`)),
    withLatestFrom(this.languageService.get(`TARIFICATION.coverage`)),
    map(([i18nPlan, coverage]) => new actions.UpdatePolicyName(`${coverage} ${i18nPlan}`)),
  );

}
