import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { RequestService } from '@app/core/services/request/request.service';
import { map, first } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { MetadataState } from '@store/metadata/metadata.model';
import { ActivityRegisterResponse, ActRegisterComponent } from './activity-register.interface';
import {
  UPDATE_REGISTER_ACTIVITY,
  REGISTER_ACTIVITY_COMPONENT,
  REGISTER_ACTIVITY_PAGE,
  REGISTER_ACTIVITY,
} from '@global/endpoints';

interface RequestObjectI {
  metadata: {};
  data?: {};
}

@Injectable({
  providedIn: 'root',
})
export class ActivityRegisterService {

  constructor(
    private readonly store: Store<AppState>,
    private readonly requestService: RequestService,
  ) {

  }

  initActRegister(requestObject: RequestObjectI): Observable<ActivityRegisterResponse> {
    return this.requestService.post(REGISTER_ACTIVITY, requestObject);
  }

  updateActRegister(activityId: string): Observable<ActivityRegisterResponse> {
    return this.requestService.put<ActivityRegisterResponse, {}>(
      REGISTER_ACTIVITY + UPDATE_REGISTER_ACTIVITY, {}, [activityId],
    );
  }

  sendActRegisterComponent(
    requestObject: RequestObjectI,
    activityId: string,
  ): Observable<ActivityRegisterResponse> {

    return this.sendActivity(requestObject, REGISTER_ACTIVITY_COMPONENT, [activityId]);
  }

  sendActRegisterEntitys(
    requestObject: RequestObjectI,
    activityId: string,
  ): Observable<ActivityRegisterResponse> {
    return this.sendActivity(requestObject, REGISTER_ACTIVITY_PAGE, [activityId]);
  }

  createMetaDataRequestObject(metadata: MetadataState): RequestObjectI {
    const requesObject = { metadata: {} };
    let metadataObject = {};
    Object.keys(metadata).forEach((key) => {
      if (key !== 'activityId') {
        metadataObject = { [key]: metadata[key], ...metadataObject };
      }
    });

    requesObject.metadata = metadataObject;
    return requesObject;
  }

  createEntitiesDataRequestObject(entities: string[]): Observable<{ data: {} }> {

    const observables = [];

    entities.forEach((entity) => {
      observables.push(this.getEntity(entity));
    });

    return this.createDataRequestObject(observables);
  }

  createComponentDataRequestObject(actRegister: ActRegisterComponent[]): Observable<{ data: {} }> {

    const observables = [];

    actRegister.forEach((entity) => {
      observables.push(this.getEntityProperties(entity));
    });

    return this.createDataRequestObject(observables);
  }

  // tslint:disable-next-line: no-any
  private createDataRequestObject(observables: any[]): Observable<{ data: {} }> {
    const requestObject = { data: null };

    return forkJoin(observables).pipe(
      map((response: {}[]) => {
        if (response) {
          response.forEach((element) => {
            requestObject.data = { ...requestObject, ...element };
          });
        }
        return requestObject;
      }),
    );
  }

  private sendActivity(requestObject: {}, url: string, params?: string[]): Observable<ActivityRegisterResponse> {
    const activityRegisterUrl = REGISTER_ACTIVITY + url;
    return this.requestService.post(activityRegisterUrl, requestObject, params);
  }

  // tslint:disable-next-line: no-any
  private getEntityProperties(actRegister: ActRegisterComponent): Observable<any> {

    return this.getEntity(actRegister.entity).pipe(
      map((entityValues) => {
        return this.createPropertiesObject(entityValues, actRegister.properties);
      }),
    );
  }

  private createPropertiesObject(entity, properties: { name: string }[]) {
    let obj = {};

    properties.forEach((property) => {
      if (entity.hasOwnProperty(property.name)) {
        obj = { ...obj, ...{ [property.name]: entity[property.name] } };
      }
    });
    return obj;
  }
  // tslint:disable-next-line: no-any
  private getEntity(entity: string): Observable<any> {
    return this.store.select(appState => appState[entity]).pipe(first());
  }

}
