import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as optionsActions from '@store/options/options.actions'

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.page.html',
  styleUrls: ['./confirm.page.scss'],
})
export class ConfirmPage implements OnInit {

  constructor(
    private readonly store: Store,
    private readonly router: Router
  ){}
  ngOnInit() {
    this.store.dispatch(new optionsActions.UpdateOptions({isHideArrow : false, confirm: true}));
    window['digitalData'].step_number = 'step3';
    window['digitalData'].step_description = 'confirm'; 
    window['digitalData'].step_common = 'step3';
    window['digitalData'].step_basic = '';
    window['digitalData'].url = window.location;
    window['digitalData'].progress_id = '3';
    try{
      if(window['stepView']){
        window['stepView']('confirm');
      }
        }catch(error){}
    
  }
  navigateTo(){
    
    this.router.navigate(['/datos-comercio']);
  }
}
