import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as Globals from '@global/constants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public authService: AuthenticationService) { }

  // tslint:disable-next-line: no-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let req = request;

    if (this.authService.currentTokenValue) {
      req = this.addToken(req, this.authService.currentTokenValue);
    }

    return next.handle(req)
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            // logOut
          }
          return throwError(error);

        }));
  }

  // tslint:disable-next-line: no-any
  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `${Globals.AUTHORIZATION} ${token}`,
      },
    });
  }
}
