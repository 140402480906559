import { Injectable } from '@angular/core';
import { RequestService } from '@app/core/services/request/request.service';
import { tap, catchError, mapTo } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Token, Jwt, TokenError } from './token-model';

@Injectable()

export class AuthenticationService {

  readonly TOKEN = 'ACSESS_TOKEN';
  readonly REFRESH_TOKEN = 'REFRESH_TOKEN';

  private readonly tokenSubject: BehaviorSubject<string>;

  constructor(private readonly requestService: RequestService) {
    this.tokenSubject = new BehaviorSubject<string>(localStorage.getItem(this.TOKEN));
  }

  public get currentTokenValue(): string {
    return this.tokenSubject.value;
  }

  getUserToken(url: string, username: string, password: string): Observable<boolean> {

    return this.requestService.post<Token, { username: string, password: string }>(url, { username, password })
      .pipe(
        tap((tokens: Token) => {
          this.storeTokens(tokens);
          this.tokenSubject.next(tokens.accessToken);
        }),
        mapTo(true),
        catchError((error: TokenError) => {
          return of(false);
        }),
      );
  }

  refreshToken(url: string) {
    return this.requestService.post<Token, { refreshToken: string }>(url, { refreshToken: this.getRefreshToken() })
      .pipe(
        tap((tokens: Token) => {
          this.storeTokens(tokens);
        }));
  }

  checkToken(token: string): { valid: boolean, expirationDate: number } | null {
    const decodedToken = this.decodeJwt(token);

    if (decodedToken) {
      return {
        valid: (decodedToken.exp >= new Date().getTime() / 1000),
        expirationDate: decodedToken.exp < new Date().getTime() / 1000 ? -1 : decodedToken.exp,
      };
    }
    return null;
  }

  localLogOut() {
    this.logOut();
  }

  globalLogOut(url: string): Observable<boolean> {
    return this.requestService.delete(url, { accessToken: this.getRefreshToken() })
      .pipe(
        tap(() => {
          this.logOut();
        }),
        mapTo(true),
        catchError((error) => {
          return of(false);
        }));

  }

  private decodeJwt(token: string): Jwt {
    let jsonPayload = token;

    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url && base64Url.replace(/-/g, '+').replace(/_/g, '/');
      jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        const str = `00${c.charCodeAt(0).toString(16)}`;
        return `%${str.slice(-2)}`;
      }).join(''));
    }

    return jsonPayload ? JSON.parse(jsonPayload) : jsonPayload;
  }

  private logOut() {
    // remove user from local storage and set current user to null
    this.tokenSubject.next(null);
    this.removeTokens();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeTokens(tokens: Token) {
    if (tokens.accessToken) {
      localStorage.setItem(this.TOKEN, tokens.accessToken);
    }
    if (tokens.refreshToken) {
      localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
    }
  }

  private removeTokens() {
    localStorage.removeItem(this.TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}
