import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmPageRoutingModule } from './confirm-routing.module';
import { ConfirmPage } from './confirm.page';
import { IonicModule } from '@ionic/angular';


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    ConfirmPageRoutingModule
  ],
  
  declarations: [
    ConfirmPage,
  ],
  exports: [
    ConfirmPage,
  ],
})
export class ConfirmPageModule { }
