import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as optionActions from '@store/options/options.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent {

  @Input() zipCode: string;
  @Input() classification: string;
  @Input() risk: string;

  constructor(
    private router: Router,
    private readonly store: Store,
  ){};

  editSelections(){
    window['digitalData'].step_basic = 'p1:quoting start'
      window['digitalData'].flow_id = '1.0';
      window['digitalData'].step_number = 'step1';
      window['digitalData'].step_description = 'datos-comercio'; 
      window['digitalData'].step_common = 'step1';
      window['digitalData'].url = window.location;
      window['digitalData'].progress_id = '1';
    this.store.dispatch(new optionActions.UpdateOptions({step : 1}));
    this.router.navigate(['/datos-comercio']);
  }
}
