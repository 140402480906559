import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as optionsActions from './store/options/options.reducer';
import * as optionActions from '@store/options/options.actions';
import * as metadataActions from './store/metadata/metadata.actions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements AfterViewInit{
   isHideArrow:boolean;
   isMobile: boolean;
   step: number = 1;
   progress: number = 0;
   confirm: boolean = false;
  privacityLink = 'https://www.mapfre.com.mx/aviso-privacidad/';
  constructor(
    private readonly platform: Platform,
    private readonly splashScreen: SplashScreen,
    private readonly statusBar: StatusBar,
    private readonly router: Router,
    private readonly store: Store,
    private readonly cdref: ChangeDetectorRef,
  ) {
    this.initializeApp();
  }
 
  ngAfterViewInit(): void {
   
  }

  initializeApp() {
    sessionStorage.setItem('dataClient', '');
    sessionStorage.setItem('client-data', 'notcompleted');
    this.platform.ready().then(() => {
      this.isMobile = this.platform.is('mobile');
      this.store.dispatch(new metadataActions.Update({isMobile: this.isMobile}));
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.store.dispatch(new metadataActions.InitActivity());
      this.getStoreValues();
    });
  }
  getStoreValues(){
    this.store.select(optionsActions.getIsHideArrow).subscribe(
      (isHideArrow)=> {
        this.isHideArrow = isHideArrow;
      })
      this.store.select(optionsActions.getConfirm).subscribe(
        (confirm)=>{
          this.confirm = confirm;
        }
      )
    this.store.select(optionsActions.getStep).subscribe(
      (step)=>{
        this.step = step;
        if(!this.isMobile){
          if(this.step === 1){
            this.progress= 0;
           }  else {
               this.progress = 100;
             } 
        }
      }
    )
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
    
     }

  deleteCookiesBanner() {
    const banner = document.getElementById('cookies-banner');
    if (banner) {
      banner.classList.add('slide-bottom');
      setTimeout(() => {
        banner.remove();
      }, 1000);
    }
  }
    
  goBack() {
    if (this.router.isActive('tarificacion', false)) {
      this.store.dispatch(new optionActions.UpdateOptions({step : 1}));
      window['digitalData'].step_basic = 'p1:quoting start';
      window['digitalData'].flow_id = '1.0';
      window['digitalData'].step_number = 'step1';
      window['digitalData'].step_description = 'datos-comercio'; 
      window['digitalData'].step_common = 'step1';
      window['digitalData'].url = window.location;
      window['digitalData'].progress_id = '1';
      this.router.navigate(['/datos-comercio']);
    }
  }

  navigateToInit() {
    this.store.dispatch(new optionActions.UpdateOptions({step : 2}));
    this.router.navigate(['/datos-comercio']);
  }
}
