import { Action } from '@ngrx/store';
import { PymeState } from './pyme.interface';

export const UPDATE_PYME = '[PYME] UPDATE_PYME';

export class UpdatePyme implements Action {
  readonly type = UPDATE_PYME;
  constructor(public changes: Partial<PymeState>) { }
}

export type pymeActions =  UpdatePyme;
