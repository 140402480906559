
export const SELECTORS = 'https://dxii03pxin5m.cloudfront.net/selectors-lite.json';

export const TARIFICATION = 'https://sxz7g3xn63.execute-api.us-east-1.amazonaws.com/pro/lite?groupId=$1&subgroupId=$2';
export const REGISTER_ACTIVITY = 'https://ts6khlq345.execute-api.eu-west-1.amazonaws.com/test/activities';
export const UPDATE_REGISTER_ACTIVITY = '/update-session?id=$1';
export const REGISTER_ACTIVITY_COMPONENT = '/$1/component-event';
export const REGISTER_ACTIVITY_PAGE = '/$1/page-event';
export const SELECT_PACKAGE = 'https://sxz7g3xn63.execute-api.us-east-1.amazonaws.com/pro/lite/select-package';

export const BUSINESS_RULES = '/business-rules';

export const GET_ENDPOINT = (url: string, params?: string[]) => {
  let uri = `${url}`;
  params && params.forEach((param, index) => {
    uri = uri.replace(`$${index + 1}`, param);
  });
  return uri;
};
