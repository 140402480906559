import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { QbComboBoxModule, QbPostalCodeMultiModule } from '@fwmovilidad/qb-lib-ng-components';
// Components across the app
import { PymeInfoPage } from './pyme-info.page';
import { PymeInfoPageRoutingModule } from './pyme-info-routing.module';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PymeInfoPageRoutingModule,
    QbComboBoxModule,
    QbPostalCodeMultiModule,
  ],
  declarations: [
    PymeInfoPage,
  ],
  exports: [
    PymeInfoPage,
  ],
})
export class PymeInfoPageModule { }
