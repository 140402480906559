import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RequestService } from '@services/request/request.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})

export class LanguageService {

  languagesSubject: BehaviorSubject<string>;

  constructor(
    private translateService: TranslateService,
    private readonly requestService: RequestService,
  ) {

    this.translateService.setDefaultLang('es');

    this.languagesSubject = new BehaviorSubject<string>('es');
  }

  changeLanguage(language: string) {

    this.translateService.use(language).subscribe(
      () => {
        this.languagesSubject.next(language);
      },
      () => {
        this.translateService.currentLang = language;
        this.languagesSubject.next(null);
      });
  }

  // tslint:disable-next-line: no-any
  updateLanguageVersion(url: string, localFileVersion?: string): Observable<any> {

    const request = this.requestService.get(url);

    return request.pipe(
      map((json) => {
        const downloadedFileVersion = json['VERSION'];
        const localVersion = localFileVersion ? localFileVersion : this.translateService.instant('VERSION');

        if (this.isServerVersionHigher(localVersion, downloadedFileVersion)) {
          this.translateService.setTranslation(this.translateService.currentLang, json, true);
          return json;
        }
        return {};
      }));
  }

  downLoadLanguage(url: string): Observable<any> {
    return this.updateLanguageVersion(url, '0.0.0');
  }

  // tslint:disable-next-line: no-any
  get(key: string | string[], interpolateParams?: Object): Observable<any> {
    return this.translateService.get(key, interpolateParams);
  }

  /**
  * @param {string} localVersion The first version to be compared.
  * @param {string} serverVersion The second version to be compared.
  *
  *  @returns {number|NaN}
  *    0 versions are equal
  *   -1 integer localVersion < serverVersion
  *    1 integer localVersion > serverVersion
  */
  private isServerVersionHigher(localVersion: string, serverVersion: string) {

    const local: number = localVersion ? Number(localVersion.split('.').join('')) : 0;
    const server: number = serverVersion ? Number(serverVersion.split('.').join('')) : 0;

    return !local || local < server;
  }

}
