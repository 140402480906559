import { Action } from '@ngrx/store';
import { DataLayerState } from './data-layer.interface';

export const UPDATE_DATALAYER = '[DATALAYER] UPDATE_DATALAYER';
export const POPUP_VIEW = '[DATALAYER] POPUP_VIEW';
export const STEP_VIEW = '[DATALAYER] STEP_VIEW';

export class UpdateDataLayer implements Action {
  readonly type = UPDATE_DATALAYER;
  constructor(public changes: Partial<DataLayerState>) { }
}

export class StepView implements Action {
  readonly type = STEP_VIEW;
  constructor(public name: string) { }
}

export class PopupView implements Action {
  readonly type = POPUP_VIEW;
  constructor(public name: string) { }
}

export type dataLayerActions = UpdateDataLayer | StepView | PopupView;
