import { Action } from '@ngrx/store';
import { ClientDataState } from './client-data.interface';

export const UPDATE_CLIENT = '[CLIENT] UPDATE_CLIENT';

export class UpdateClient implements Action {
  readonly type = UPDATE_CLIENT;
  constructor(public changes: Partial<ClientDataState>) { }
}

export type clientActions = UpdateClient;
