import * as actions from './metadata.actions';
import { MetadataState } from './metadata.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import moment from 'moment';

function getBrowser(): string {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'Opera';
    // tslint:disable-next-line: no-else-after-return
  } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari';
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox';
  } else if ((navigator.userAgent.indexOf('MSIE') !== -1)) {
    return 'IE';
  } else {
    return 'unknown';
  }
}

export const initialState: MetadataState = {
  date: moment().format('L'),
  time: moment().format('LTS'),
  browser: getBrowser(),
  location: moment().locale(),
  activityId: null,
  isMobile: false
};

export function metadataReducer(state: MetadataState = initialState, action: actions.metadataActions) {

  switch (action.type) {

    case actions.UPDATE:

      return { ...state, ...action.changes };

    default:
      return state;
  }
}

// Create default selectors
export const getMetadataState = createFeatureSelector<MetadataState>('metadata');
export const getMetadata = createSelector(
  getMetadataState,
  (state: MetadataState) => state,
);

export const getActivityId = createSelector(
  getMetadataState,
  (state: MetadataState) => state.activityId,
);

export const getIsMobile = createSelector(
  getMetadataState,
  (state: MetadataState) => state.isMobile,
)