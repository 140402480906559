import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormErrorHandler } from '@core/error-handler/form-error-handler';
import { AppState } from '@store/app.state';
import { Store } from '@ngrx/store';
import { debounceTime } from 'rxjs/operators';
import * as actions from '@store/pyme/pyme.actions';
import * as dataLayerActions from '@store/data-layer/data-layer.actions';
import { RequestService } from '@app/core/services/request/request.service';
import { objectValidator, fullPostalCodeValidator } from '@shared/validations/custom-validations';
import * as metadataActions from '@store/metadata/metadata.actions';
import * as fromPyme from '@store/pyme/pyme.reducer';
import { SELECTORS } from '@global/endpoints';
import * as optionsActions from '@store/options/options.actions'
@Component({
  selector: 'app-pyme-info',
  templateUrl: './pyme-info.page.html',
  styleUrls: ['./pyme-info.page.scss'],
})

export class PymeInfoPage implements OnInit {

  inputs = [{
    placeholder: '0',
    maxlength: 1,
  },
  {
    placeholder: '6',
    maxlength: 1,
  },
  {
    placeholder: '6',
    maxlength: 1,
  },
  {
    placeholder: '0',
    maxlength: 1,
  },
  {
    placeholder: '0',
    maxlength: 1,
  },
  ];

  form: FormGroup;
  errors = {};
  classificationValues$;
  riskValues;

  constructor(
    private router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly formErrorHandler: FormErrorHandler,
    private readonly store: Store<AppState>,
    private readonly requestService: RequestService,
  ) {

    this.form = this.formBuilder.group({
      location: [null, [fullPostalCodeValidator]],
      classification: [null, [Validators.required, objectValidator]],
      risk: [null, [Validators.required, objectValidator]],
    });
  }                                                                                                                                                                                                                       

  ngOnInit() {
 
    this.store.dispatch(new optionsActions.UpdateOptions({isHideArrow : false, confirm: false}));
    this.store.dispatch(new optionsActions.UpdateOptions({step: 1}));
    this.formErrorHandler.handleErrors(this.form, this.errors);
    this.saveSingleFormControl();
    this.updateDataLayer();
    this.getComboBoxValues();
    this.getStoreValues();
    sessionStorage.setItem('client', '');
    try{
      setTimeout (() => {
        if(window['stepView']){
          if(sessionStorage.getItem('dataClient') === '' || (sessionStorage.getItem('client-data')==='completed') && sessionStorage.getItem('dataClient')==='true'){
    
            window['stepView']('datos-comercio');
          }          
        }
     }, 1000);     
      }catch(error){ }
      window['digitalData'].step_basic = 'p1:quoting start'
      window['digitalData'].flow_id = '1.0';
      window['digitalData'].step_number = 'step1';
      window['digitalData'].step_description = 'datos-comercio'; 
      window['digitalData'].step_common = 'step1';
      window['digitalData'].url = window.location;
      window['digitalData'].progress_id = '1';
  }



  getStoreValues() {                                                                                                      
    this.store.select(fromPyme.getClassification).subscribe(
      (classification) => {
        this.form.get('classification').setValue(classification);
        this.riskValues = this.form.get('classification').value?.subgroups;
      },
    );

    this.store.select(fromPyme.getRisk).subscribe(
      (risk) => {
        this.form.get('risk').setValue(risk);
      },
    );

    this.store.select(fromPyme.getLocation).subscribe(
      (location) => {
        this.form.get('location').setValue(location);
      },
    );
  }

  getComboBoxValues() {
    this.classificationValues$ = this.requestService.get(SELECTORS);
  }

  navigateTo() {
    this.store.dispatch(new dataLayerActions.StepView('datos-comercio'));
    this.store.dispatch(new metadataActions.SendEntitysActivity(['pyme']));
    this.router.navigate(['/tarificacion']);
  }

  private saveSingleFormControl() {

    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).statusChanges.pipe(
        debounceTime(300))
        .subscribe(() => {
          if (this.form.get(key).valid) {
            this.store.dispatch(new actions.UpdatePyme({ [key]: this.form.get(key).value }));
          }
        });
    });
  }

  private updateDataLayer() {
    this.store.dispatch(new dataLayerActions.UpdateDataLayer({ step_number: '1', step_description: 'pyme-info' }));
  }

  isZipCodeCompleted() {
    if (this.form.get('location').value && this.form.get('location').value.zipCode) {
      return this.form.get('location').value.zipCode.length === 5;
    }
  }

  isFormValid() {
    return this.form.valid && this.form.get('location').value && this.form.get('location').value.state;
  }
}
