
import * as actions from './policy.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PolicyState } from './policy.interface';

export const initialState: PolicyState = {
  name: '',
  i18nName: '',
  totalPrime: null,
  vatAmount: null,
  vatPercent: null,
};

export function policyReducer(state: PolicyState = initialState, action: actions.policyActions) {

  switch (action.type) {
    case actions.UPDATE_POLICY:
      return {
        ...state,
        ...action.changes,
      };
    case actions.UPDATE_POLICY_NAME:
      return {
        ...state,
        i18nName: action.name,
      };
    default:
      return state;

  }
}

// Create default selectors
export const getPolicyState = createFeatureSelector<PolicyState>('policy');
export const getPolicy = createSelector(
  getPolicyState,
  (state: PolicyState) => state,
);
export const getName = createSelector(
  getPolicy,
  (state: PolicyState) => state.name,
);

export const geti18nName = createSelector(
  getPolicy,
  (state: PolicyState) => state?.i18nName,
);

