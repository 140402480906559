
import { Injectable } from '@angular/core';
import { LanguageService } from '@services/language/language.service';
import { mergeMap, tap } from 'rxjs/operators';
import { ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})

export class ValidationErrorService {

  private readonly genericError = 'Este campo es invalido';
  private messages: { [key: string]: string } = {
    required: 'Este campo es obligatorio',
    phoneValidator: 'El formato no es válido',
  };

  constructor(private readonly languageService: LanguageService) {
    this.getTranslatedMessages().subscribe();
  }

  private getTranslatedMessages() {
    return this.languageService.languagesSubject.pipe(
      mergeMap(() => {
        return this.languageService.get('ERRORS').pipe(
          tap(response => this.messages = response),
        );
      }),
    );
  }

  getMessage(error: ValidationErrors): string {
    const key = error ? Object.keys(error)[0] : null;
    const requiredValue = key ? this.getRequiredValue(error) : null;
    const message = requiredValue ? this.replace(this.messages[key], requiredValue) : this.messages[key];

    return !message ? this.genericError : message;
  }

  private getRequiredValue(error: ValidationErrors) {
    let length;

    if (error.min) {
      length = error.min.min;
    } else if (error.max) {
      length = error.max.max;
    } else if (error.minlength) {
      length = error.minlength.requiredLength;
    } else if (error.maxlength) {
      length = error.maxlength.requiredLength;
    }
    return length;
  }

  private replace(message: string, param: string | number): string {
    return message.replace('{{ value }}', param.toString());
  }
}
