import { AbstractControl, ValidationErrors } from '@angular/forms';

export function emailValidator(control: AbstractControl) {
  const haveAddressNick = RegExp(/^(([a-zA-Z\-0-9\.\_\-]){2,})@/);
  if (haveAddressNick.test(control.value)) {
    const haveAddressServer = RegExp(/@([a-zA-Z.-]{2,})/);
    if (haveAddressServer.test(control.value)) {
      const haveAddressDomain = RegExp(/\.[a-zA-Z]{2,3}$/);
      if (haveAddressDomain.test(control.value)) {
        return null;
      }
    }
  }
  return { emailValidator: true };
}

export function phoneMXValidator(control: AbstractControl) {

  if(/^\D*\d{10}$/.test(control.value)){
    if(control.value.length === 10){
      const repetitions = validateRepetitions(control.value);
      if(repetitions){
        const valid = validateConsecutive(control.value);
        if(valid){
          const result = validateConsecutiveDesc(control.value);
          if(result){
            return null;
          }
        }
      }
    }
  } 

  return { phoneMXValidator: true };
}

function validateRepetitions(control){
  const numbers = control.split('');
  var search = 1;
  for(var i=1; i<numbers.length; i++){
    if(numbers[i] === numbers[0]){
      search ++;
    }
  }
  return (search === numbers.length) ? false: true;
}
function validateConsecutive(control){
 const total = control.split('');
  for(var i = 0; i<total.length-1; i++){
   if((Number.parseInt(total[i+1], 10) !== Number.parseInt(total[i], 10)+1)){
    if(!(Number.parseInt(total[i], 10) === 9 && Number.parseInt(total[i+1], 10) ===0)){
     return true;
    }
  }
 } 
  return false;
}
function validateConsecutiveDesc(control){
  const total = control.split('');
  for(var i = 0; i<total.length-1; i++){
    if((Number.parseInt(total[i], 10) !== Number.parseInt(total[i+1], 10)+1)){
      if(!(Number.parseInt(total[i], 10) === 0 && Number.parseInt(total[i+1], 10)===9)){
       return true;
      }
    }
  }
  return false;
}
export function objectValidator(control: AbstractControl) {
  let failed = false;

  if (control.value) {
    Object.keys(control.value).forEach((key) => {
      if (!control.value[key]) {
        failed = true;
      }
    });
  }
  return failed ? { objectValidator: true } : null;
}

function postalCodeValidator(control: AbstractControl) {
  let failed = false;

  if (control.value && control.value.zipCode) {
    if (!control.value.state) {
      failed = true;
    }

  }
  return failed ? { postalCodeValidator: true } : null;
}

function postalCodeFormatValidator(control: AbstractControl) {
  let failed = false;
  if (control.value) {
    if (control.value.zipCode && !/^[0-9]*$/.test(control.value.zipCode)) {
      failed = true;
    }
  }
  return failed ? { postalCodeFormatValidator: true } : null;
}

export function fullPostalCodeValidator(control: AbstractControl) {

  if (postalCodeFormatValidator(control)) {
    return postalCodeFormatValidator(control);
  }
  if (postalCodeValidator(control)) {
    return postalCodeValidator(control);
  }
  return null;
}

export function jsonValidator(control: AbstractControl): ValidationErrors | null {
  try {
    JSON.parse(control.value);
  } catch (e) {
    return { jsonInvalid: true };
  }

  return null;
}
