import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as actions from './price.actions';
import { PriceState } from './price.interface';

export const initialState: PriceState = {
  coverageTable: null,
  priceResume: null
};

export function priceReducer(state: PriceState = initialState, action: actions.priceActions) {

  switch (action.type) {

    case actions.UPDATE_PRICE:
      return {
        ...state,
        ...action.changes,
      };
    default:
      return state;

  }
}


export const getPriceState = createFeatureSelector<PriceState>('priceReducer');
export const getTarifications = createSelector(
  getPriceState,
  (state: PriceState) => state,
);
export const getCoverages = createSelector(
  getPriceState,
  (state: PriceState) => state.coverageTable,
);
export const getPrices = createSelector(
  getPriceState,
  (state: PriceState) => state.priceResume,
);

