import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TarificationPageModule } from '@pages/tarification/tarification.module';
import { PymeInfoPageModule } from '@pages/pyme-info/pyme-info.module';
import { ConfirmPageModule } from '@pages/confirm/confirm.module';
const routes: Routes = [
  {
    path: 'datos-comercio',
    loadChildren: () => import('@pages/pyme-info/pyme-info.module').then(m => PymeInfoPageModule),
  },
  {
    path: 'tarificacion',
    loadChildren: () => import('@pages/tarification/tarification.module').then(m => TarificationPageModule),
  },
  {
    path: 'confirm',
    loadChildren: () => import('@pages/confirm/confirm.module').then(m => ConfirmPageModule)
  },
  { path: '', redirectTo: '/datos-comercio', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
