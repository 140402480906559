import { Action } from '@ngrx/store';
import { OptionsDataState } from './options.interface';

export const UPDATE_OPTIONS = '[CLIENT] UPDATE_OPTIONS';

export class UpdateOptions implements Action {
  readonly type = UPDATE_OPTIONS;
  constructor(public changes: Partial<OptionsDataState>) { }
}

export type optionsActions = UpdateOptions;
