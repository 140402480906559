export enum LEVEL {
  debug,
  info,
  warn,
  error,
  fatal,
}

export class GlobalError {
  statusCode: number;
  message: string;
  level: LEVEL;

  constructor(statusCode: number, message: string, level: LEVEL) {
    this.statusCode = statusCode;
    this.message = message;
    this.level = level;
  }
}
