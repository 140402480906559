
import { ErrorHandler, Injectable } from '@angular/core';
import { GlobalError, LEVEL } from './global-error';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.

  handleError(error: GlobalError) {
    this.printLog(error);
  }

  private printLog(error: GlobalError) {
    switch (error && error.level) {
      case LEVEL.debug:
        // tslint:disable-next-line: no-console
        console.debug(error);
        break;
      case LEVEL.warn:
        console.warn(error);
        break;
      case LEVEL.info:
        // tslint:disable-next-line: no-console
        console.info(error);
        break;
      default:
        console.error(error);
    }
  }

}
