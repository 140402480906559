import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as actions from './options.actions';
import { OptionsDataState } from './options.interface';

export const initialState: OptionsDataState = {
  isHideArrow: false,
  step:1,
  confirm: false
};

export function optionsReducer(state: OptionsDataState = initialState, action: actions.optionsActions) {

  switch (action.type) {

    case actions.UPDATE_OPTIONS:
      return {
        ...state,
        ...action.changes,
      };
    default:
      return state;

  }
}

// Create default selectors
export const getOptionsState = createFeatureSelector<OptionsDataState>('options');
export const getClient = createSelector(
  getOptionsState,
  (state: OptionsDataState) => state,
);
export const getIsHideArrow = createSelector(
  getOptionsState,
  (state: OptionsDataState) => state.isHideArrow,
);
export const getStep = createSelector(
  getOptionsState,
  (state: OptionsDataState) => state.step
)
export const getConfirm = createSelector(
  getOptionsState,
  (state: OptionsDataState) => state.confirm
)