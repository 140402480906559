import { Component, OnInit } from '@angular/core';
import { FormErrorHandler } from '@core/error-handler/form-error-handler';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as ClientActions from '@store/client-data/client-data.actions';
import { phoneMXValidator, emailValidator } from '@shared/validations/custom-validations';
import { MatDialogRef } from '@angular/material/dialog';
import * as fromClient from '@store/client-data/client-data.reducer';
import { Router } from '@angular/router';
import * as dataLayerActions from '@store/data-layer/data-layer.actions';
import * as metadataActions from '@store/metadata/metadata.actions';
import * as optionActions from '@store/options/options.actions';
import * as clientActions from '@store/client-data/client-data.actions';
@Component({
  selector: 'app-client-data',
  templateUrl: './client-data.component.html',
  styleUrls: ['./client-data.component.scss'],
})
export class QbClientDataComponent implements OnInit {

  form: FormGroup;
  errors = {
    name: null,
    phone: null,
    email: null,
    terms: null,
  };

  constructor(
    public dialogRef: MatDialogRef<QbClientDataComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly formErrorHandler: FormErrorHandler,
    private readonly store: Store,
    private readonly router: Router,
  ) {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      phone: [null, [Validators.required, phoneMXValidator]],
      email: [null, [Validators.required, emailValidator]],
      terms: [null, [Validators.requiredTrue]]
    });
  }

  ngOnInit() {
    this.formErrorHandler.handleErrors(this.form, this.errors);
    this.saveSingleFormControl();
    this.getStoreValues();
    this.updateDataLayer();
   
      try{
        if(window['popupView']){
          window['popupView']('client-data');     
        }
          }catch(error){}
  }

  getStoreValues() {

    this.store.select(fromClient.getName).subscribe(
      (name) => {
        this.form.get('name').setValue(name);
      },
    );

    this.store.select(fromClient.getPhone).subscribe(
      (phone) => {
        this.form.get('phone').setValue(phone);
      },
    );

    this.store.select(fromClient.getEmail).subscribe(
      (email) => {
        this.form.get('email').setValue(email);
      },
    );

    this.store.select(fromClient.getTerms).subscribe(
      (terms) => {
        this.form.get('terms').setValue(terms);
      },
    );
  }

  navigateBack() {
    this.store.dispatch(new optionActions.UpdateOptions({step: 1}));
    this.store.dispatch(new metadataActions.SendEntitysActivity(['client']));
    this.router.navigate(['/datos-comercio']);
    if(this.form.get('name').value && this.form.get('phone').value && this.form.get('email').value && this.form.get('terms').value){
      sessionStorage.setItem('client-data', 'completed');
      sessionStorage.setItem('dataClient', 'true');
    }else{
      sessionStorage.setItem('dataClient', 'false');
    }
    this.store.dispatch(new clientActions.UpdateClient({terms: true}));
    this.dialogRef.close();
  }
  sendData(){
    this.store.dispatch(new dataLayerActions.PopupView('datos-cliente'));
    this.store.dispatch(new metadataActions.SendEntitysActivity(['client']));
    sessionStorage.setItem('client-data', 'completed');
    sessionStorage.setItem('dataClient', 'true');
    sessionStorage.setItem('client', 'ok');
    this.store.dispatch(new clientActions.UpdateClient({terms: true}));
    this.dialogRef.close();
  }
  close() {
    this.store.dispatch(new clientActions.UpdateClient({terms: false}));
    this.dialogRef.close();
  }

  checked(value) {
    this.form.get('terms').setValue(value);
  }

  private saveSingleFormControl() {

    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).statusChanges.pipe(
        debounceTime(300))
        .subscribe(() => {
          if (this.form.get(key).valid) {
            this.store.dispatch(new ClientActions.UpdateClient({ [key]: this.form.get(key).value }));
          }
        });
    });
  }

  private updateDataLayer() {
    this.store.dispatch(new dataLayerActions.UpdateDataLayer({ step_number: '2', step_description: 'client-data' }));
  }

}
