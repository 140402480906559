import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormErrorHandler } from '@core/error-handler/form-error-handler';
import { RopoService } from '@services/ropo/ropo.service';
import { phoneMXValidator } from '@shared/validations/custom-validations';

@Component({
  selector: 'app-ropo',
  templateUrl: './ropo.component.html',
  styleUrls: ['./ropo.component.scss'],
})
export class RopoComponent implements OnInit {

  form: FormGroup;
  errors = { phone: null };

  constructor(
    private readonly ropoService: RopoService,
    private readonly formErrorHandler: FormErrorHandler,
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<RopoComponent>,
  ) {
    this.form = this.formBuilder.group({
      phone: [null, [Validators.required, phoneMXValidator]],
    });
  }

  ngOnInit() {
    this.formErrorHandler.handleErrors(this.form, this.errors);
  }

  callMe() {
    this.ropoService.callMe(this.form.value.phone);
  }

  close() {
    this.dialogRef.close();
  }
}
