import { Injectable } from '@angular/core';
import { FormControl, FormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ValidationErrorService } from '@services/validation-error/validation-errors.service';

export declare interface ServerError {
  [key: string]: [];
}

@Injectable()
export class FormErrorHandler {

  private message: string;
  private errorObject: ServerError;
  form: FormGroup;

  constructor(private readonly validationErrorService: ValidationErrorService){}

  private static hasError(control: AbstractControl): boolean {
    return control.invalid && (control.dirty || control.touched);
  }

  /**
  * Listen's for invalid status of the form given and find's it's errors.
  *
  * @param form              Form to be listened
  * @param errorObject       Error object which to set errors.
  */

  public handleErrors(form: FormGroup, errorObject: {}) {
    this.form = form;
    this.errorObject = errorObject;

    form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.findErrors(form.controls);
    });
  }

  /**
 * Find which control contains the error and set required { control -> error message } combination
 * into the errorObject given previously.
 *
 * @param controls      Abstract Controls of the form which contains errors.
 */
  private findErrors(controls: { [key: string]: AbstractControl }) {
    Object.keys(controls).forEach((control: string) => {
      if (controls[control] instanceof FormControl) {
        this.findErrorsOnFormControls(controls, control);
      }
    });
  }

  private findErrorsOnFormControls(controls: { [key: string]: AbstractControl }, control: string) {
    if (FormErrorHandler.hasError(controls[control])) {
      this.setErrorMessage(controls[control].errors);
      this.setErrorToErrorObject(control, this.message);
    } else {
      this.setErrorToErrorObject(control, null);
    }
  }

  private setErrorMessage(errors: ValidationErrors) {
    if (errors && Object.keys(errors).length > 0) {
      this.message = this.validationErrorService.getMessage(errors);
    }
  }

  /**
   * Set's a new property to errorObject with key from the field's name and error message as a value.
   * @param field         Field which contains error.
   */
  private setErrorToErrorObject(field: string, message: string) {
    Object.defineProperty(this.errorObject, field, { value: message, writable: true });
  }
}
