import { ActionReducerMap } from '@ngrx/store';
import { pymeReducer } from './pyme/pyme.reducer';
import { metadataReducer } from './metadata/metadata.reducer';
import { clientReducer } from './client-data/client-data.reducer';
import { policyReducer } from './policy/policy.reducer';
import { dataLayerReducer } from './data-layer/data-layer.reducer';
import { optionsReducer } from './options/options.reducer';
import { priceReducer } from './price/price.reducer';

// tslint:disable-next-line: no-any
const reducers: ActionReducerMap<any> = {
  pyme: pymeReducer,
  client: clientReducer,
  metadata: metadataReducer,
  policy: policyReducer,
  dataLayer: dataLayerReducer,
  options: optionsReducer,
  priceReducer: priceReducer
};

export interface AppState {
}

export { reducers };
