import * as actions from './pyme.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PymeState } from './pyme.interface';

export const initialState: PymeState = {
  location: {
    zipCode: null,
    state: null,
    population: null,
  },
  risk: {
    id: null,
    value: null,
  },
  classification: {
    id: null,
    value: null,
  },
};

export function pymeReducer(state: PymeState = initialState, action: actions.pymeActions) {

  switch (action.type) {

    case actions.UPDATE_PYME:
      const currentValue = state.classification?.id;
      if (action.changes.classification && currentValue !== action.changes.classification?.id) {
        return {
          ...state,
          ...action.changes,
          risk: null,
        };
      }

      return { ...state, ...action.changes };

    default:
      return state;

  }
}

export const getPymeState = createFeatureSelector<PymeState>('pyme');

export const getPyme = createSelector(
  getPymeState,
  (state: PymeState) => state,
);
export const getLocation = createSelector(
  getPyme,
  (state: PymeState) => state.location,
);
export const getLocationZipCode = createSelector(
  getPyme,
  (state: PymeState) => state.location.zipCode,
);
export const getLocationState = createSelector(
  getPyme,
  (state: PymeState) => state.location.state,
);
export const getLocationPopulation = createSelector(
  getPyme,
  (state: PymeState) => state.location.population,
)
export const getRisk = createSelector(
  getPyme,
  (state: PymeState) => state.risk,
);
export const getRiskValue = createSelector(
  getPyme,
  (state: PymeState) => state.risk.value,
);
export const getRiskId = createSelector(
  getPyme,
  (state: PymeState) => state.risk.id,
);
export const getClassification = createSelector(
  getPyme,
  (state: PymeState) => state.classification,
);
export const getClassificationValue = createSelector(
  getPyme,
  (state: PymeState) => state.classification.value,
);
export const getClassificationId = createSelector(
  getPyme,
  (state: PymeState) => state.classification.id,
);
