import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as actions from './client-data.actions';
import { ClientDataState } from './client-data.interface';

export const initialState: ClientDataState = {
  name: '',
  phone: '',
  email: '',
  terms: false,
};

export function clientReducer(state: ClientDataState = initialState, action: actions.clientActions) {

  switch (action.type) {

    case actions.UPDATE_CLIENT:
      return {
        ...state,
        ...action.changes,
      };
    default:
      return state;

  }
}

// Create default selectors
export const getClientState = createFeatureSelector<ClientDataState>('client');
export const getClient = createSelector(
  getClientState,
  (state: ClientDataState) => state,
);
export const getName = createSelector(
  getClientState,
  (state: ClientDataState) => state.name,
);
export const getEmail = createSelector(
  getClientState,
  (state: ClientDataState) => state.email,
);
export const getPhone = createSelector(
  getClientState,
  (state: ClientDataState) => state.phone,
);
export const getTerms = createSelector(
  getClientState,
  (state: ClientDataState) => state.terms,
);
