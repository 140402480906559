import * as actions from './data-layer.actions';
import { DataLayerState } from './data-layer.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const initialState: DataLayerState = {
  error_type: '',
  error_message: '',
  process_id: '',
  full_operation_id: '',
  registration_operation_id: '',
  flow_id: '',
  step_number: '',
  step_description: '',
  step_common: '',
  step_basic: '',
  step_version: '',
  progress_id: '',
};

export function dataLayerReducer(state = initialState, action: actions.dataLayerActions) {

  switch (action.type) {

    case actions.UPDATE_DATALAYER:
      return {
        ...state,
        ...action.changes,
      };

    default:
      return state;

  }
}
// Create default selectors
export const getDataLayerState = createFeatureSelector<DataLayerState>('dataLayer');
export const getDataLayer = createSelector(
  getDataLayerState,
  (state: DataLayerState) => state,
);
