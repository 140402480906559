import { Action } from '@ngrx/store';
import { PriceState } from './price.interface';

export const UPDATE_PRICE = '[TARIFICATION] UPDATE_PRICE_PACKAGE';

export class UpdatePrice implements Action {
  readonly type = UPDATE_PRICE;
  constructor(public changes: Partial<PriceState>) { }
}

export type priceActions = UpdatePrice;
