import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class RopoService {

  callMe(phone: number) {
    console.log(`Call me ${phone}`);
  }

}
