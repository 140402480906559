import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CoverageTableI } from './coverage-table.interface';
import { PriceResumeI } from './tarification.interface';

@Component({
  selector: 'qb-tarification-mx',
  templateUrl: './tarification-mx.component.html',
  styleUrls: ['./tarification-mx.component.scss'],
})
export class QbTarificationMxComponent {

  @Input() planTitle: string;
  @Input() fromLabel: string;
  @Input() button: string;
  @Input() more: string;
  @Input() less: string;
  @Input() visibleCategories = 4;
  @Input() toShow: string;
  @Input() coveragesTitle: string;
  @Input() currency: string;
  @Input() price: PriceResumeI;
  @Input() coverages: CoverageTableI[];
  @Input() loading: boolean;
  @Output() clicked = new EventEmitter<PriceResumeI>();
  countCategories = this.visibleCategories;

  constructor() { }

  emitEvent() {
    this.clicked.emit(this.price);
  }

  getFullCoverageInsured(coverage: CoverageTableI) {
    let modality = null;
    if (coverage && coverage.modalities) {
      modality = coverage.modalities.find(el => el.name.toLowerCase() === this.price.name.toLowerCase());
    }
    return modality ? modality.fullCoverageInsured : '';
  }

  foldUnfold(el: HTMLElement) {
    if (el.hidden) {
      el.hidden = false;
    } else {
      el.hidden = true;
    }
  }

  isCoverageInsuredHidden(el: HTMLElement) {
    return el.hidden;
  }

  showMore() {
    if (this.toShow === this.more) {
      this.visibleCategories = this.coverages.length;
      this.toShow = this.less;
    } else {
      this.visibleCategories = this.countCategories;
      this.toShow = this.more;
    }
  }
}
