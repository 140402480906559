import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Components across the app
import { QbTarificationMxComponent } from './tarification-mx.component';
//import { QbSuggestionComponent } from './suggestion/suggestion.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    QbTarificationMxComponent,
    //QbSuggestionComponent,
  ],
  exports: [
    QbTarificationMxComponent,
    //QbSuggestionComponent,
  ],
})
export class QbTarificationMxModule { }
