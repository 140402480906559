import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { withLatestFrom, tap } from 'rxjs/operators';
import * as actions from './data-layer.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import * as fromDataLayer from './data-layer.reducer';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';

@Injectable({
  providedIn: 'root',
})

export class DataLayerEffects {

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<AppState>,
  ) { }

  @Effect({ dispatch: false })

  StepView$ = this.actions$.pipe(
    ofType<actions.StepView>(actions.STEP_VIEW),
    withLatestFrom(this.store$.select(fromDataLayer.getDataLayer)),
    tap((actionAndStoreState) => {
      this.sendData('step-view', actionAndStoreState);
    }),
  );

  @Effect({ dispatch: false })

  PopupView$ = this.actions$.pipe(
    ofType<actions.PopupView>(actions.POPUP_VIEW),
    withLatestFrom(this.store$.select(fromDataLayer.getDataLayer)),
    tap((actionAndStoreState) => {
      this.sendData('popup', actionAndStoreState);
    }),
  );

  private sendData(action: string, actionAndStoreState) {

    const eventName = `${action}-${actionAndStoreState[0].name}`;
    const store = actionAndStoreState[1];

    Object.keys(store).forEach((key) => {
      firebase.analytics().logEvent(eventName, {
        [key]: store[key],
      });
    });
  }
}
