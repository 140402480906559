import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromClient from '@store/client-data/client-data.reducer';
import * as actions from '@store/client-data/client-data.actions';
import * as dataLayerActions from '@store/data-layer/data-layer.actions';
import { debounceTime } from 'rxjs/operators';
import { FormErrorHandler } from '@core/error-handler/form-error-handler';
import { emailValidator, phoneMXValidator } from '@shared/validations/custom-validations';
import { AppState } from '@store/app.state';
import { Observable } from 'rxjs';
import * as fromPolicy from '@store/policy/policy.reducer';
import { RopoService } from '@services/ropo/ropo.service';
import * as metadataActions from '@store/metadata/metadata.actions';
import { Router } from '@angular/router';
import * as metadataReducer from '@store/metadata/metadata.reducer';
import * as pymeReducer  from '@app/store/pyme/pyme.reducer';
import { SELECT_PACKAGE } from '@global/endpoints';
import { RequestService } from '@app/core/services/request/request.service';
import * as fromPrice from '@store/price/price.reducer';


@Component({
  selector: 'app-modal-call-me',
  templateUrl: './modal-call-me.component.html',
  styleUrls: ['./modal-call-me.component.scss'],
})
export class ModalCallMeComponent implements OnInit {

  form: FormGroup;
  errors = {
    name: null,
    phone: null,
    email: null,
  };
  price: string;
  name: Observable<string>;
  phone: Observable<string>;
  email: Observable<string>;
  personalData;
  policyData;
  pymeData;
  tarificationData;
  coverageSelected: Observable<string>;
  calling: boolean;
  isMobile: Observable<boolean>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly store: Store<AppState>,
    public dialogRef: MatDialogRef<ModalCallMeComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly formErrorHandler: FormErrorHandler,
    private readonly ropoService: RopoService,
    private readonly router: Router,
    private readonly requestService: RequestService,
  ) {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      phone: [null, [Validators.required, phoneMXValidator]],
      email: [null, [Validators.required, emailValidator]],
    });
  }

  ngOnInit() {   
    this.coverageSelected = this.store.select(fromPolicy.geti18nName);
    this.isMobile =  this.store.select(metadataReducer.getIsMobile);
    this.store.select(fromClient.getClientState).subscribe((data)=>{
      this.personalData = data; 
    });
    this.store.select(fromPolicy.getPolicyState).subscribe((data)=>{
      this.policyData = data;
    });
    this.store.select(pymeReducer.getPyme).subscribe((data)=>{
      this.pymeData = data;
    });
    this.store.select(fromPrice.getTarifications).subscribe(
      (tarification)=>{
      this.tarificationData = tarification;
    });
    this.formErrorHandler.handleErrors(this.form, this.errors);
    this.getStoreValues();
    this.saveSingleFormControl();
    this.updateDataLayer();
   
    window['digitalData'].step_basic = '';
    window['digitalData'].url = window.location;
  
    try{
      if(window['popupView']){
        window['popupView']('confirm_data');
      }
        }catch(error){}
  }

  getStoreValues() {                                                                                                      
    this.store.select(fromClient.getName).subscribe(
      (name) => {
        this.form.get('name').setValue(name);
      },
    );

    this.store.select(fromClient.getPhone).subscribe(
      (phone) => {
        this.form.get('phone').setValue(phone);
      },
    );

    this.store.select(fromClient.getEmail).subscribe(
      (email) => {
        this.form.get('email').setValue(email);
      },
    );
  }

  private saveSingleFormControl() {

    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).statusChanges.pipe(
        debounceTime(300))
        .subscribe(() => {
          if (this.form.get(key).valid) {
            this.store.dispatch(new actions.UpdateClient({ [key]: this.form.get(key).value }));
          }
        });
    });
  }

  private updateDataLayer() {
    this.store.dispatch(new dataLayerActions.UpdateDataLayer({ step_number: '4', step_description: 'modal-call-me' }));
  }

  cancel() {
    this.dialogRef.close();
    window['digitalData'].step_basic = 'p1:quoting start'
    window['digitalData'].step_number = 'step1';
    window['digitalData'].step_description = 'datos-comercio'; 
    window['digitalData'].step_common = 'step1';
    window['digitalData'].url = window.location;
    window['digitalData'].progress_id = '1';
    this.router.navigate(['/']);
  }

  confirmData() {
    let confirmData = {
      name: this.personalData.name,
      email:this.personalData.email,
      phone: this.personalData.phone,
      terms: this.personalData.terms,
      policy: this.policyData.name,
      policyType: this.policyData.i18nName,
      pymeZipCode: this.pymeData.location.zipCode,
      pymeState: this.pymeData.location.state,
      pymePopulation:this.pymeData.location.population,
      pymeRisk:this.pymeData.risk.value,
      pymeClassification:this.pymeData.classification.value,
      coverageTable: this.tarificationData.coverageTable,
      priceResume: this.tarificationData.priceResume,
    }
      this.sendConfirm(confirmData).subscribe((response) =>{ 
        this.store.dispatch(new dataLayerActions.PopupView('modal-llamame'));
        this.store.dispatch(new metadataActions.SendEntitysActivity(['client']));
        this.ropoService.callMe(this.form.get('phone').value);
        this.dialogRef.close();
        this.router.navigate(['/confirm']);
    });
   
  }
  sendConfirm(selection): Observable<any>{
    return this.requestService.post(SELECT_PACKAGE, selection);
  }
  isFormValid() {
    return this.form.valid && this.form.get('name').value && this.form.get('phone').value && this.form.get('email').value;
  }

}
