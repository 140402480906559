import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, tap, mergeMap, withLatestFrom, catchError, switchMap } from 'rxjs/operators';
import * as actions from './metadata.actions';
import { ActivityRegisterService } from '@services/activity-register/activity-register.service';
import { Store } from '@ngrx/store';
import * as fromMetadata from './metadata.reducer';
import { ActRegisterComponent } from '@services/activity-register/activity-register.interface';

@Injectable({
  providedIn: 'root',
})

export class MetadataEffects {

  private entities: string[];
  private component: ActRegisterComponent[];

  private finishedMessage = 'Invalid Activity Registry Event - ActivityId finished';
  private expiredMessage = 'Invalid Activity Registry Event - ActivityId expired';

  constructor(
    private readonly activityRegisterService: ActivityRegisterService,
    private readonly actions$: Actions,
    private readonly store: Store,
  ) {

    this.finishedMessage = this.finishedMessage.toLowerCase().trim();
    this.expiredMessage = this.expiredMessage.toLowerCase().trim();
  }

  @Effect()
  // tslint:disable-next-line: no-any
  InitActivity$: Observable<any> = this.actions$.pipe(
    ofType<actions.InitActivity>(actions.INIT_ACTIVITY),
    withLatestFrom(this.store.select(fromMetadata.getMetadata)),
    map(([action, metadata]) => this.activityRegisterService.createMetaDataRequestObject(metadata)),
    mergeMap(requestObject => this.activityRegisterService.initActRegister(requestObject)
      .pipe(
        map(response => new actions.Update({ activityId: response.activityId })),
      ),
    ),
  );

  @Effect()
  // tslint:disable-next-line: no-any
  UpdateActivityId$: Observable<any> = this.actions$.pipe(
    ofType<actions.UpdateActivityId>(actions.UPDATE_ACTIVITY_ID),
    withLatestFrom(this.store.select(fromMetadata.getActivityId)),
    mergeMap(([action, activityId]) => this.activityRegisterService.updateActRegister(activityId)
      .pipe(
        map(response => new actions.Update({ activityId: response.activityId })),
      ),
    ),
  );

  @Effect()
  // tslint:disable-next-line: no-any
  SendActEntitiesRegister$: Observable<any> = this.actions$.pipe(
    ofType<actions.SendEntitysActivity>(actions.SEND_ENTITY_ACTIVITY),
    tap(action => this.entities = action.entities),
    mergeMap(action => this.activityRegisterService.createEntitiesDataRequestObject(action.entities)),
    withLatestFrom(this.store.select(fromMetadata.getMetadata)),
    map(([data, metadata]) => {
      return { data, ...this.activityRegisterService.createMetaDataRequestObject(metadata) };
    }),
    withLatestFrom(this.store.select(fromMetadata.getActivityId)),
    mergeMap(([request, activityId]) => this.activityRegisterService.sendActRegisterEntitys(request, activityId)
      .pipe(
        map(() => new actions.SendEntitysActivitySuccess()),
        catchError((error) => {
          if (error?.message.toLowerCase().trim() === this.finishedMessage) {
            return of(new actions.SendEntitysActivityFinished(this.entities));
          }
          if (error?.message.toLowerCase().trim() === this.expiredMessage) {
            return of(new actions.SendEntitysActivityExpired(this.entities));
          }
          return of(new actions.SendEntitysActivityFailed());
        }),
      ),
    ),
  );

  @Effect()
  // tslint:disable-next-line: no-any
  SendEntityActivityExpired$: Observable<any> = this.actions$.pipe(
    ofType<actions.SendEntitysActivityExpired>(actions.SEND_ENTITY_ACTIVITY_EXPIRED),
    withLatestFrom(this.store.select(fromMetadata.getActivityId)),
    switchMap(([action, activityId]) =>
      this.activityRegisterService.updateActRegister(activityId).pipe(
        map(response => this.dispatchEntityRegisterActions(response, action),
        ),
      ),
    ),
  );

  @Effect()
  // tslint:disable-next-line: no-any
  SendEntitysActivityFinished$: Observable<any> = this.actions$.pipe(
    ofType<actions.SendEntitysActivityFinished>(actions.SEND_ENTITY_ACTIVITY_FINISHED),
    withLatestFrom(this.store.select(fromMetadata.getMetadata)),
    switchMap(([action, metadata]) =>
      this.activityRegisterService.initActRegister(
        this.activityRegisterService.createMetaDataRequestObject(metadata)).pipe(
          map(response => this.dispatchEntityRegisterActions(response, action),
          ),
        ),
    ),
  );

  // COMPONENTE

  @Effect()
  // tslint:disable-next-line: no-any
  SendComponentActivity$: Observable<any> = this.actions$.pipe(
    ofType<actions.SendComponentActivity>(actions.SEND_COMPONENT_ACTIVITY),
    tap(action => this.component = action.data),
    mergeMap(action => this.activityRegisterService.createComponentDataRequestObject(action.data)),
    withLatestFrom(this.store.select(fromMetadata.getMetadata)),
    map(([data, metadata]) => {
      return { data, ...this.activityRegisterService.createMetaDataRequestObject(metadata) };
    }),
    withLatestFrom(this.store.select(fromMetadata.getActivityId)),
    mergeMap(([request, activityId]) => this.activityRegisterService.sendActRegisterComponent(request, activityId)
      .pipe(
        map(() => new actions.SendComponentActivitySuccess()),
        catchError((error) => {
          if (error?.message.toLowerCase().trim() === this.finishedMessage) {
            return of(new actions.SendComponentActivityFinished(this.component));
          }
          if (error?.message.toLowerCase().trim() === this.expiredMessage) {
            return of(new actions.SendComponentActivityExpired(this.component));
          }
          return of(new actions.SendComponentActivityFailed());
        }),
      ),
    ),
  );

  @Effect()
  // tslint:disable-next-line: no-any
  SendComponentActivityExpired$: Observable<any> = this.actions$.pipe(
    ofType<actions.SendComponentActivityExpired>(actions.SEND_COMPONENT_ACTIVITY_EXPIRED),
    withLatestFrom(this.store.select(fromMetadata.getActivityId)),
    switchMap(([action, activityId]) =>
      this.activityRegisterService.updateActRegister(activityId).pipe(
        map(response => this.dispatchComponentRegisterActions(response, action),
        ),
      ),
    ),
  );

  @Effect()
  // tslint:disable-next-line: no-any
  SendComponentActivityFinished$: Observable<any> = this.actions$.pipe(
    ofType<actions.SendComponentActivityFinished>(actions.SEND_COMPONENT_ACTIVITY_FINISHED),
    withLatestFrom(this.store.select(fromMetadata.getMetadata)),
    switchMap(([action, metadata]) =>
      this.activityRegisterService.initActRegister(
        this.activityRegisterService.createMetaDataRequestObject(metadata)).pipe(
          map(response => this.dispatchComponentRegisterActions(response, action),
          ),
        ),
    ),
  );

  private dispatchEntityRegisterActions(response, action) {
    return [
      new actions.Update({ activityId: response.activityId }),
      new actions.SendEntitysActivity(action.entities),
    ];
  }

  private dispatchComponentRegisterActions(response, action) {
    return [
      new actions.Update({ activityId: response.activityId }),
      new actions.SendComponentActivity(action.data),
    ];
  }

}
