import { Action } from '@ngrx/store';
import { PolicyState } from './policy.interface';

export const UPDATE_POLICY = '[POLICY] UPDATE_POLICY';
export const UPDATE_POLICY_NAME = '[POLICY] UPDATE_POLICY_NAME';

export class UpdatePolicy implements Action {
  readonly type = UPDATE_POLICY;
  constructor(public changes: Partial<PolicyState>) { }
}

export class UpdatePolicyName implements Action {
  readonly type = UPDATE_POLICY_NAME;
  constructor(public name: string) { }
}

export type policyActions = UpdatePolicy | UpdatePolicyName;
