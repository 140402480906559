import { NgModule, ErrorHandler, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorHandler } from './error-handler/global-error-handler';
import { FormErrorHandler } from './error-handler/form-error-handler';
import { HttpInterceptorService } from './interceptors/http-interceptor';
import { AuthenticationService } from './authentication/authentication.service';
import { TokenInterceptor } from './interceptors/token-interceptor';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    AuthenticationService,
    FormErrorHandler,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
    }
  }
}
