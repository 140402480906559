import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

// Components across the app
import { QbTarificationMxModule } from '@components/tarification-mx/tarification-mx.module';
import { TarificationPage as TarificationPage } from './tarification.page';
import { ModalCallMeModule } from '@components/modal-call-me/modal-call-me.module';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { SummaryModule } from '@components/summary/summary.module';
import { TarificationPageRoutingModule } from './tarification-routing.module';
import { ClientDataPageModule } from '@app/components/client-data/client-data.component.module';
import { IonicModule } from '@ionic/angular';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TarificationPageRoutingModule,
    ModalCallMeModule,
    QbTarificationMxModule,
    ClientDataPageModule,
    SwiperModule,
    SummaryModule,
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
  ],
  declarations: [
    TarificationPage,
  ],
  exports: [
    TarificationPage,
  ],
})
export class TarificationPageModule { }
