import { Component, OnInit, ViewChild } from '@angular/core';
import { AppState } from '@store/app.state';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { QbClientDataComponent } from '@components/client-data/client-data.component';
import { ModalCallMeComponent } from '@components/modal-call-me/modal-call-me.component';
import * as actions from '@store/policy/policy.actions';
import { Observable, zip } from 'rxjs';
import * as fromClient from '@store/client-data/client-data.reducer';
import { map, tap, mergeMap } from 'rxjs/operators';
import * as dataLayerActions from '@store/data-layer/data-layer.actions';
import { GET_ENDPOINT, TARIFICATION } from '@global/endpoints';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { LanguageService } from '@services/language/language.service';
import { RequestService } from '@services/request/request.service';
import { getClassification, getLocation, getRisk } from '@app/store/pyme/pyme.reducer';
import * as optionsActions from '@store/options/options.actions';
import * as fromPyme from '@store/pyme/pyme.reducer';
import { Router } from '@angular/router';
import * as metadataActions from '@store/metadata/metadata.reducer';
import { TarificationI, PriceResumeI } from '@components/tarification-mx/tarification.interface';
import { CoverageTableI } from '@components/tarification-mx/coverage-table.interface';
import { IonSlides } from '@ionic/angular';
import * as priceResume from '@store/price/price.actions';
@Component({
  selector: 'app-tarification',
  templateUrl: './tarification.page.html',
  styleUrls: ['./tarification.page.scss'],
})
export class TarificationPage implements OnInit {
  @ViewChild('mySlider') slides: IonSlides;
  zipCode: string;
  classification;
  risk;
  location;
  currency: string;
  locale: string;
  prices: PriceResumeI[] | string[];
  coverages: CoverageTableI[] | string[];
  activeColumn = 2;
  isMobile: boolean;
  private isClientModalOpen: boolean;

  classPaginator = 'swiper-pagination-bullet-active';
 
  slideOptsOne: { allowTouchMove: boolean; slidesPerView: number;} = {
    slidesPerView: 3,
    allowTouchMove: false,
   
  }
  tarificationLoading = true;

  constructor(
    private readonly store: Store<AppState>,
    private readonly requestService: RequestService,
    public dialog: MatDialog,
    private readonly languageService: LanguageService,
    private router: Router,
  ) {
    // Datos por defecto para que visualmente se vea el skeleton con 3 columnas
    this.prices = ['1', '2', '3'];
    this.coverages = ['1', '2', '3'];
  }

  ngOnInit() {
    if(sessionStorage.getItem('client-data') === 'completed'){
      window['digitalData'].step_basic = 'p2:price view';
      try{
        if(window['stepView']){
          window['stepView']('tarification');
        }
          }catch(error){}
    }
    this.store.dispatch(new optionsActions.UpdateOptions({isHideArrow : true}));
    this.store.dispatch(new optionsActions.UpdateOptions({step : 2}));
    this.store.select(metadataActions.getIsMobile).subscribe(
      (isMobile) => {
        this.isMobile = isMobile;
        if(isMobile){
          this.slideOptsOne = {
            slidesPerView: 1.2,
            allowTouchMove: true,
        
          }
        }
      }
    )
    this.updateDataLayer();
    this.store.select(fromPyme.getLocation).subscribe(
      (location) => {
       this.location = location;
      },
    );

    this.store.select(fromClient.getClient).subscribe((client) => {
      if(client.name && client.email && client.phone && client.terms){
        window['digitalData'].step_number = 'step2';
        window['digitalData'].step_description = 'tarification'; 
        window['digitalData'].step_common = 'step2';
        window['digitalData'].step_basic = 'p2:price view';
        window['digitalData'].url = window.location;
        window['digitalData'].progress_id = '2';
        sessionStorage.setItem('client-data', 'completed');
      } 
    })
    if(sessionStorage.getItem('client-data')!=='completed'){
      this.openModal();
    }

    this.getTarificationData().subscribe((response) => {
      this.tarificationLoading = false;
      this.prices = response.priceResume;
      this.coverages = response.coverageTable;
      this.store.dispatch(new priceResume.UpdatePrice({coverageTable: this.coverages}));
      this.currency = response.currency;
      response.priceResume[0].suggestion = false;
      response.priceResume[1].suggestion = true;
      response.priceResume[2].suggestion = false;
      if(sessionStorage.getItem('client-data') !== 'completed'){
        window['digitalData'].step_basic = '';
      }
    });    
  }

  private getTarificationData() {
    return zip(this.store.select(getClassification), this.store.select(getRisk), this.store.select(getLocation))
      .pipe(
        tap((response) => {
          this.classification = response[0];
          this.risk = response[1];
          this.zipCode = response[2]?.zipCode;
        }),
        mergeMap(
          values => this.getData(values[0]?.id, values[1]?.id).pipe(
            map(response => response),
          ),
        ),
      );
  }

  private updateDataLayer() {
    this.store.dispatch(new dataLayerActions.UpdateDataLayer({ step_number: '3', step_description: 'tarification' }));
  }

  openModal() {
    this.store.dispatch(new dataLayerActions.StepView('tarificacion'));
    this.isClientModalOpen = true;
    const dialogRef = this.dialog.open(QbClientDataComponent, {
      disableClose: true,
      backdropClass: 'custom-backdrop-background',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.isClientModalOpen = false;
      if(sessionStorage.getItem('client')==='ok'){
        try{
          if(window['stepView']){
            window['stepView']('tarification');
          }
            }catch(error){}
      }
    });
  }

  blur() {
    return this.isClientModalOpen ? { '  filter': `blur(5px)`, '-webkit-filter': `blur(5px)` } : false;
  }

  getData(classificationId: string, riskId: string) {
    return this.requestService.get<TarificationI>(GET_ENDPOINT(
      TARIFICATION, [classificationId, riskId],
    ));
  }
  public nextSlide() {
    this.slides.slideNext();
  }

  public prevSlide() {
    this.slides.slidePrev();
  }

  public getColumns() {
    return {
      'grid-template-columns': `repeat(${this.prices?.length + 1}, 1fr)`,
    };
  }

  showModalCallMe(coverage: PriceResumeI) {
    this.store.dispatch(new priceResume.UpdatePrice({priceResume: coverage}));
    this.store.dispatch(new actions.UpdatePolicy({ 
      name: coverage.name, 
      totalPrime: coverage.totalPrime,
      vatAmount: coverage.vatAmount,
      vatPercent: coverage.vatPercent,
    }));
    
    this.dialog.open(ModalCallMeComponent, {
      disableClose: true,
      backdropClass: 'custom-backdrop-background',
      data: {
       price: coverage.totalPrime.toFixed(2)
          
      }
    });
  }

  getPlanTitle(name: string): Observable<string> {
    return this.languageService.get(`TARIFICATION.coverage`).pipe(
      mergeMap(coverage => this.languageService.get(`TARIFICATION.${name}`).pipe(
        map(planName => `${coverage} ${planName}`),
      )),
    );
  }

}
